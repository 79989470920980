import React from 'react';
import _ from 'lodash';

import {markdownify} from '../utils';

export default class HeroBlock extends React.Component {
    render() {
        return (
            <section id={_.get(this.props, 'section.section_id')} className="block hero-block outer">
              <div className="inner-small">
                <div className="block-header">
                  <h1 className="block-title"><span>{_.get(this.props, 'section.title')}</span></h1>
                  <h3 className="subtitle-hero">{_.get(this.props, 'section.subtitle')}</h3>
                </div>
                {/* <div className="block-content">
                  <div className="block-copy">
                    {markdownify(_.get(this.props, 'section.content'))}
                  </div>
                </div>
                <svg height="0" width="0" style={{position: "absolute"}}>
                  <defs>
                    <clipPath id="rounded-rectangle">
                        <rect x="560" y="450" width="1224" height="529" rx="265" transform="rotate(-30 1 612)"></rect>
                    </clipPath>
                  </defs>
                </svg> */}
                {/* <div className="hero-video">
                  <div className="hero-video-overlay"></div>
                  <video src="https://res.cloudinary.com/dshbwx0n7/video/upload/v1582727351/mv-video-intro_vqyss0.mp4" loop muted autoPlay></video>
                  seu navegador não suporta HTML5
                </div> */}
              </div>
            </section>
        );
    }
}
