import React from 'react'
import _ from 'lodash';


export default class ClientSlide extends React.Component {

  render() {
    let clientsList = null;

    clientsList = (
      <ul className="list-clients">
        {_.map(_.get(this.props, 'pageContext.site.data.clients.clientslist'), (client, client_idx) => (
          <li key={client_idx} className="list-clients--item">
          <div className="list-clients--container">
            <img src={client.clientlogo} alt={client.clientname} title={client.clientname} />
          </div>
          </li>
        ))}
      </ul>
    )

    return (
        <section className="clients-section">
          <h1 className="page-title line-top">Nossos Clientes</h1>
          { clientsList }
        </section>
    )
  }
}