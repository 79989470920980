import React from 'react';
import _ from 'lodash';

import {getPages, Link, safePrefix, htmlToReact} from '../utils';

export default class Service2Block extends React.Component {
    render() {

        let display_services = _.orderBy(getPages(this.props.pageContext.pages, '/servicos'), 'desc');
        const services_match = display_services.map((service, service_idx) => {
            return (
                <div key={service_idx} className='content-service'>
                    <Link to={safePrefix(_.get(service, 'url'))} rel="bookmark" className='link-service'>
                        <div className="grid-service">
                            <img className="thumbnail" src={safePrefix(_.get(service, 'frontmatter.thumb_img_path_service'))} alt={_.get(service, 'frontmatter.title')} />
                            <div className='flex-text'>
                                <h2>{_.get(service, 'frontmatter.title')}</h2>
                                < hr className="hr-p" />
                                <p>{ _.get(service, 'frontmatter.subtitle') }</p>
                            </div>
                        </div>
                    </Link>
                        
                </div>
                
            )
        })

        return (
                <>
                    <section className="multivideo-services">
                        <div className="support-service-grid">
                            { services_match }
                        </div>
                    </section>
                </>
        );
    }
}
